import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, filter, firstValueFrom, lastValueFrom, Subject, take } from 'rxjs';

import { AqiType } from '@libs/common/enums/aqi.type';
import { InfoMessage } from '@cityair/namespace';
import { isOldBrowser } from '@cityair/utils/utils';
import { isRU, TEXTS } from '@libs/common/texts/texts';
import { detectMobile } from '@libs/common/utils/detect-mobile';
import { MapAdapterService } from '@cityair/modules/map/services/map-adapter.service';
import {
    canClickOnMarker,
    selectAvailableModule,
    selectErrorMessage,
    selectInfoMessage,
    selectIsInstantAqiFaqOpen,
    selectIsShowQualityDataInfo,
    selectIsSidebarOpen,
    selectLoadingTimeline,
    selectMainLoading,
    selectQualityDataMarkers,
    selectTimeRange,
    selectUserData,
} from './modules/core/store/selectors';
import { getCurrentGroup, selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import {
    addAlert,
    changeQualityDataMode,
    clearErrorMessage,
    clickFromApToMo,
    closeInstantAqiFaq,
    closeSidebar,
    initCityScreenPage,
    logOut,
    openSidebar,
    removeAlert,
    setComparisonMode,
    toggleShowQualityDataInfo,
    toggleSidebar,
    updateTimeRangeData,
} from './modules/core/store/actions';

import { RoutingService } from './modules/core/routing.service';
import { environment } from 'environments/environment';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { getModulePageConfig } from './modules/core/store/module-page-config/module-page-config.feature';
import { GroupExtConfigName } from '@libs/common/enums/group-ext-config-name';
import { GroupFeaturesService } from './modules/core/services/group-features/group-features.service';
import MapboxActions from './modules/map/components/mapbox/mapboxActions';
import { selectComparedItems } from './modules/core/store/compared-list/compared-list.selectors';
import { getClientAssetPath, getShiftMapCityCard, getShiftMapMobile } from './config';
import { selectGroupList } from './modules/core/store/group/group.feature';
import { NavigationEnd, Router } from '@angular/router';
import { ClientConfigService } from '@cityair/modules/core/services/client-config/client-config.service';
import { ChangeGroupHelperService } from '@cityair/modules/core/services/change-group-helper.service';
import { FeedbackApiService } from '@cityair/modules/core/services/api/feedback-api';
import { FormErrors } from '@libs/common/models/formErrors';
import { selectAllPostsDic } from '@cityair/modules/core/store/posts/posts.feature';
import { COMPARE_LIMIT } from '@cityair/libs/shared/utils/other-utils';

const templateNotSupported = `
    <div class="browser_not_support">{{TEXTS.COMMON.browserNotSupport}}</div>
`;

const templateDesktop = `
    <ca-tooltip-outlet></ca-tooltip-outlet>
    <ca-popup-outlet></ca-popup-outlet>
    <ng-container *ngIf="settingsLoaded">
        <ng-container *ngIf="useOpenLayersMap else mapboxTemp">
             <cityair-ol-map
                [ngStyle]="{visibility: (moduleConfig$ | async).enableMap ? 'visible' : 'hidden'}"
                class="{{(moduleConfig$ | async)?.mapClassName ?? ''}}"
                [postPins]="mapAdapter.postPins"
                [zoom]="mapAdapter.zoom"
                [center]="mapAdapter.center"

                [notificationSelectedPins]="mapAdapter.notificationsSelectedPosts"
                [cityPins]="mapAdapter.cityPins"
                [controlPointPins]="mapAdapter.controlPointPins"
                [correlationPins]="mapAdapter.correlationPins"
                [polygon]="mapAdapter.polygon"
                [infoPins]="mapAdapter.infoPins"
                [sourceLine]="mapAdapter.sourceLine"
                [groupFeaturesLayer]="mapAdapter.groupFeaturesLayer"
                [pinsAreaData]="mapAdapter.pinsAreaData"

                (mapDragEnd)="mapAdapter.onMapDragEnd?.($event)"
                (zoomChanged)="mapAdapter.onMapZoomChanged?.($event)"
            ></cityair-ol-map>
        </ng-container>
        <ng-template #mapboxTemp>
            <mapbox-map [ngStyle]="{visibility: (moduleConfig$ | async).enableMap ? 'visible' : 'hidden'}"
                class="{{(moduleConfig$ | async)?.mapClassName ?? ''}}"
                [postPins]="mapAdapter.postPins"
                [zoom]="mapAdapter.zoom"
                [center]="mapAdapter.center"

                [notificationSelectedPins]="mapAdapter.notificationsSelectedPosts"
                [correlationPins]="mapAdapter.correlationPins"
                [sourceLine]="mapAdapter.sourceLine"
                [cityPins]="mapAdapter.cityPins"
                [controlPointPins]="mapAdapter.controlPointPins"
                [polygon]="mapAdapter.polygon"
                [infoPins]="mapAdapter.infoPins"
                [groupFeaturesLayer]="mapAdapter.groupFeaturesLayer"
                [pinsAreaData]="mapAdapter.pinsAreaData"

                (mapDragEnd)="mapAdapter.onMapDragEnd?.($event)"
                (zoomChanged)="mapAdapter.onMapZoomChanged?.($event)"
            ></mapbox-map>
        </ng-template>
    </ng-container>

    <ng-container [ngSwitch]="routingService.pageChange$ | async">
        <forecast-map *ngSwitchCase="MAIN_PAGES.forecast"></forecast-map>
        <notifications-map *ngSwitchCase="MAIN_PAGES.notifications"></notifications-map>
        <plumes-map *ngSwitchCase="MAIN_PAGES.plumes"></plumes-map>
        <cityair-analysis-map *ngSwitchCase="MAIN_PAGES.analysis"></cityair-analysis-map>
        <default-map *ngSwitchDefault></default-map>
    </ng-container>

    <div class="admin_panel">
        <div class="sidebar">
            <shared-ui-sidebar-menu
                [userData]="store.select(selectUserData) | async"
                [groupList]="store.select(selectGroupList) | async"
                [currentGroupId]="(store.select(selectGroupId) | async)"
                [assets]="assets"
                [availableModules]="availableModules"
                [activeModule]="activeModule"
                (changeGroup)="changeGroup($event)"
                (selectModule)="selectModule($event)"
                (showFeedback)="showFeedback()"
                (logOut)="logOut()"
            ></shared-ui-sidebar-menu>
            <div
                class="sidebar_container"
                [ngClass]="{
                    'sidebar_container-active': store.select(selectIsSidebarOpen) | async,
                    'sidebar_container-full_width': activeModule === MAIN_PAGES.indoor }"
            >
                <ca-sidebar-toggle-button
                    *ngIf="routeIsNotEmpty() && !routingService.isActiveRoute(MAIN_PAGES.indoor)"
                    [isActive]="store.select(selectIsSidebarOpen) | async"
                    (toggle)="store.dispatch(toggleSidebar())"
                ></ca-sidebar-toggle-button>

                <router-outlet></router-outlet>
            </div>
            <cs-feedback-form
                class="sidebar__feedback-form"
                *ngIf="showFeedbackForm"
                [success]="feedbackForm.success$"
                [errors]="feedbackForm.errors$"
                (submitForm)="submitFeedback($event)"
                (closeForm)="showFeedbackForm = false"
            ></cs-feedback-form>
        </div>
    </div>

    <loader_map *showDirective="store.select(selectMainLoading) | async"></loader_map>
    <stnd-error-popup *ngIf="store.select(selectErrorMessage) | async"
        [errorMsg]="store.select(selectErrorMessage) | async"
        [close]="closeErrorPopup"
    ></stnd-error-popup>
    <download-popups-wrapper></download-popups-wrapper>
    <instant-aqi-faq *ngIf="store.select(selectIsInstantAqiFaqOpen) | async" (closeModal)="closeInstantAqiFaq()"></instant-aqi-faq>
   <!-- <cs-onboarding *ngIf="isRu" featureName="map-style-07-2024"></cs-onboarding>-->
    <info-message *ngIf="infoMessage?.length" [data]="infoMessage" (hide)="hideInfoMessage($event)"></info-message>
    <cityair-data-quality
         *ngIf="store.select(selectIsShowQualityDataInfo) | async as data"
         [data]="data"
         [qualityDataPercent]="qualityDataMode"
         [dataMarkers]="store.select(selectQualityDataMarkers) | async"
         (closeModal)="closeDataQualityInfo()"
     ></cityair-data-quality>
`;

const templateMobile = `
    <ca-tooltip-outlet></ca-tooltip-outlet>
    <ca-popup-outlet></ca-popup-outlet>

    <ng-container *ngIf="settingsLoaded">
        <ng-container *ngIf="useOpenLayersMap else mapboxTemp">
            <cityair-ol-map
                [ngStyle]="{display: (moduleConfig$ | async).enableMap ? '' : 'none'}"
                [postPins]="mapAdapter.postPins"
                [zoom]="mapAdapter.zoom"
                [center]="mapAdapter.center"

                [notificationSelectedPins]="mapAdapter.notificationsSelectedPosts"
                [cityPins]="mapAdapter.cityPins"
                [controlPointPins]="mapAdapter.controlPointPins"

                [groupFeaturesLayer]="mapAdapter.groupFeaturesLayer"
                [pinsAreaData]="mapAdapter.pinsAreaData"

                (mapDragEnd)="mapAdapter.onMapDragEnd?.($event)"
                (zoomChanged)="mapAdapter.onMapZoomChanged?.($event)"
            ></cityair-ol-map>
        </ng-container>
        <ng-template #mapboxTemp>
            <mapbox-map  [ngStyle]="{display: (moduleConfig$ | async).enableMap ? '' : 'none'}"
                class="{{(moduleConfig$ | async)?.mapClassName ?? ''}}"
                [postPins]="mapAdapter.postPins"
                [zoom]="mapAdapter.zoom"
                [center]="mapAdapter.center"

                [notificationSelectedPins]="mapAdapter.notificationsSelectedPosts"
                [cityPins]="mapAdapter.cityPins"
                [controlPointPins]="mapAdapter.controlPointPins"

                [groupFeaturesLayer]="mapAdapter.groupFeaturesLayer"
                [pinsAreaData]="mapAdapter.pinsAreaData"

                (mapDragEnd)="mapAdapter.onMapDragEnd?.($event)"
                (zoomChanged)="mapAdapter.onMapZoomChanged?.($event)"
            ></mapbox-map>
        </ng-template>
    </ng-container>
    <ng-container [ngSwitch]="routingService.pageChange$ | async">
        <indoor-main-mobile *ngSwitchCase="MAIN_PAGES.indoor"></indoor-main-mobile>
        <plumes-map-mobile *ngSwitchCase="MAIN_PAGES.plumes"></plumes-map-mobile>
        <indoor-widget *ngSwitchCase="MAIN_PAGES.indoorWidget"></indoor-widget>
        <default-map-mobile *ngSwitchDefault></default-map-mobile>
    </ng-container>

    <loader_map *showDirective="store.select(selectLoadingTimeline) | async"></loader_map>
    <stnd-error-popup *ngIf="store.select(selectErrorMessage) | async"
        [errorMsg]="store.select(selectErrorMessage) | async"
        [close]="closeErrorPopup"
    ></stnd-error-popup>
    <download-popups-wrapper></download-popups-wrapper>
    <instant-aqi-faq *ngIf="store.select(selectIsInstantAqiFaqOpen) | async" (closeModal)="closeInstantAqiFaq()"></instant-aqi-faq>
    <info-message *ngIf="infoMessage?.length" [data]="infoMessage" (hide)="hideInfoMessage($event)"></info-message>
`;

enum Views {
    NOT_SUPPORTED = 'not-supported',
    MOBILE = 'mobile',
    MAP = 'map',
}

@Component({
    selector: 'map-page',
    styleUrls: ['mapPage.less'],
    template: `
        <ng-container [ngSwitch]="currentView">
            <ng-container *ngSwitchDefault>${templateDesktop}</ng-container>

            <ng-container *ngSwitchCase="views.MOBILE">${templateMobile}</ng-container>

            <ng-container *ngSwitchCase="views.NOT_SUPPORTED">${templateNotSupported}</ng-container>
        </ng-container>
    `,
})
export class CityScreenPage implements OnInit {
    TEXTS = TEXTS;
    isRu = isRU;
    views = Views;
    currentView: string;

    AqiType = AqiType;
    settingsLoaded = false;
    useOpenLayersMap: boolean;
    selectIsInstantAqiFaqOpen = selectIsInstantAqiFaqOpen;
    selectLoadingTimeline = selectLoadingTimeline;
    selectErrorMessage = selectErrorMessage;
    selectGroupId = selectGroupId;
    selectIsSidebarOpen = selectIsSidebarOpen;
    selectGroupList = selectGroupList;
    toggleSidebar = toggleSidebar;
    selectIsShowQualityDataInfo = selectIsShowQualityDataInfo;
    selectQualityDataMarkers = selectQualityDataMarkers;
    selectUserData = selectUserData;
    selectMainLoading = selectMainLoading;

    MAIN_PAGES = MAIN_PAGES;
    assets: Record<string, string> = {};
    availableModules: MAIN_PAGES[];
    activeModule: MAIN_PAGES;
    showToggleButton = true;
    showFeedbackForm = false;
    moduleConfig$ = this.store.select(getModulePageConfig);
    feedbackForm = {
        success$: new Subject<void>(),
        errors$: new Subject<FormErrors>(),
    };
    currentGroupId: string;
    public infoMessage: InfoMessage[];
    public qualityDataMode = null;

    private loadedGroupInfo$ = this.store.select(selectGroupId).pipe(
        filter((groupId) => !!groupId),
        take(1)
    );

    private loadedTimelineInfo$ = this.store.select(selectLoadingTimeline).pipe(
        filter((val) => val === false),
        take(1)
    );

    public firstLoad$ = combineLatest([this.loadedGroupInfo$, this.loadedTimelineInfo$]).pipe(
        take(1)
    );

    constructor(
        public routingService: RoutingService,
        readonly store: Store,
        readonly mapAdapter: MapAdapterService,
        private groupFeaturesService: GroupFeaturesService,
        private mapActions: MapboxActions,
        private router: Router,
        readonly clientConfigService: ClientConfigService,
        private changeGroupHelper: ChangeGroupHelperService,
        readonly feedbackApiService: FeedbackApiService
    ) {
        this.currentView = isOldBrowser()
            ? Views.NOT_SUPPORTED
            : detectMobile()
            ? Views.MOBILE
            : Views.MAP;
        store.dispatch(initCityScreenPage());
        store
            .select(getCurrentGroup)
            .pipe(filter((g) => !!g))
            .subscribe((group) => {
                this.currentGroupId = group.id;
                this.useOpenLayersMap = group.ext_config && group.ext_config?.useOpenLayersMap;
                this.settingsLoaded = true;
            });
        store.select(selectInfoMessage).subscribe((info) => {
            this.infoMessage = info;
        });

        store.pipe(selectTimeRange).subscribe(async () => {
            this.store.dispatch(updateTimeRangeData());
        });

        this.store
            .select(selectAvailableModule)
            .pipe(filter((v) => !!v.length))
            .subscribe((modules) => {
                this.availableModules = modules;
                const page = this.routingService.getActivePage() as MAIN_PAGES;
                if (page && modules.length && modules.indexOf(page) === -1) {
                    this.routingService.goToPage(MAIN_PAGES.default);
                    this.store.dispatch(closeSidebar());
                }
            });

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.activeModule = this.router.url.split('/')[1] as MAIN_PAGES;
                this.showToggleButton =
                    !!this.activeModule || this.activeModule !== MAIN_PAGES.indoor;
            }
        });
        this.initAssets();
    }

    ngOnInit() {
        this.firstLoad$.subscribe(async () => {
            await this.initQualityData();
            await this.initDefaultMo();
        });

        this.feedbackApiService.errorMessage$
            .pipe(filter((message) => !!message))
            .subscribe((message) => {
                this.feedbackForm.errors$.next(null);
                this.store.dispatch(
                    addAlert({
                        id: new Date().valueOf(),
                        message,
                        positionX: 'left',
                        positionY: 'bottom',
                        iconClass: 'error',
                        duration: 2000,
                        showCloseIcon: false,
                        size: 'lg',
                    })
                );
            });
    }

    private async initDefaultMo() {
        const defaultMo = this.groupFeaturesService.getDefaultMo();
        if (defaultMo.length > COMPARE_LIMIT) {
            defaultMo.length = COMPARE_LIMIT;
        }

        if (defaultMo) {
            this.store.dispatch(setComparisonMode({ payload: defaultMo.length > 1 }));
            const markers = await lastValueFrom(
                this.store.select(selectAllPostsDic).pipe(
                    filter((v) => !!v),
                    take(1)
                )
            );

            for (let i = 0; i < defaultMo.length; i++) {
                const id = defaultMo[i];
                const fixedId = `post_${id}`;
                const marker = markers[id] ?? markers[fixedId] ?? null;
                if (marker) {
                    const canClick = await lastValueFrom(
                        this.store.select(canClickOnMarker(fixedId)).pipe(take(1))
                    );
                    if (canClick) {
                        this.store.dispatch(clickFromApToMo({ moObjId: fixedId }));
                    }
                }
            }

            // передвигаем карту
            const marker = (await firstValueFrom(this.store.select(selectComparedItems)))[0];

            if (marker) {
                setTimeout(() => {
                    this.mapActions.centringOnMarker(
                        marker.geometry.coordinates[1],
                        marker.geometry.coordinates[0],
                        true,
                        detectMobile() ? getShiftMapMobile() : getShiftMapCityCard()
                    );
                });
            }
        }
    }

    private async initQualityData() {
        const qualityMode = this.groupFeaturesService.getConfig(
            GroupExtConfigName.dataQualityPercent
        );

        if (qualityMode && !environment.isAWS) {
            this.qualityDataMode = qualityMode;
            this.store.dispatch(changeQualityDataMode({ payload: this.qualityDataMode }));
        }
    }

    closeInstantAqiFaq() {
        this.store.dispatch(closeInstantAqiFaq());
    }

    closeDataQualityInfo() {
        this.store.dispatch(toggleShowQualityDataInfo({ payload: null }));
    }

    closeErrorPopup = () => this.store.dispatch(clearErrorMessage());

    hideInfoMessage(infoMessage: InfoMessage) {
        this.store.dispatch(removeAlert({ payload: infoMessage }));
    }

    public routeIsNotEmpty = () => !!this.router.url.substring(1);

    public selectModule($event) {
        this.router.navigate([$event], {});
        this.store.dispatch(openSidebar());
    }

    public changeGroup(groupId) {
        this.changeGroupHelper.changeGroup(groupId);
    }

    public showFeedback() {
        this.showFeedbackForm = true;
    }

    public submitFeedback(message: string) {
        this.feedbackApiService.sendFeedback(message, this.currentGroupId).subscribe(() => {
            this.feedbackForm.success$.next();
            this.store.dispatch(
                addAlert({
                    id: new Date().valueOf(),
                    message: TEXTS.FEEDBACK.successTitle,
                    description: TEXTS.FEEDBACK.successMessage,
                    positionX: 'left',
                    positionY: 'bottom',
                    iconClass: 'success',
                    duration: 4000,
                    showCloseIcon: true,
                    size: 'lg',
                })
            );
        });
    }

    private initAssets() {
        const clientConfig = this.clientConfigService.getConfig();

        const logoSize = clientConfig.sidebar?.logoWidth;
        const logo = clientConfig.sidebar?.logo;

        this.assets = {
            styleBackgroundSize: logoSize > 0 && `${logoSize}px auto`,
            logo: logo && 'url(' + getClientAssetPath(logo) + ')',
        };
    }

    logOut() {
        this.store.dispatch(logOut());
    }
}
